<template>
  <div class="row">
    <div class="col-md-12">
      <table class="table table-sm table-bordered">
        <thead>
          <tr>
            <th>Tanggal</th>
            <th>Ikhtisar Klinik</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <div class="result_tab">
                {{isParent.rowEdit.aranrp_date | moment('DD MMM YYYY, HH:mm')}} WIB
              </div>
            </td>
            <td>
              <pre class="pre-input">
              {{isParent.rowEdit.aranrp_ikhtisar||"-"}}
              </pre>
            </td>
          </tr>

        </tbody>
      </table>

      <!-- v-if="isParent.rowEdit.aranrp_status == 'WAITING'" -->
      <template v-if="isParent.rowEdit.aranrp_status == 'WAITING'">
        <div class="text-center mt-3">
          <div class="modal-shout-icon mb-3 d-inline-block alpha-info text-info rounded-circle">
            <i class="fa-solid fa-question"></i>
          </div>
          <h6 class="mb-0">Terdapat permintaan Alih Rawat untuk pasien {{isParent.rowEdit.ap_fullname||"-"}} dari {{isParent.rowEdit.dokter_perujuk||"-"}}. </h6>
          <h3 class="font-weight-semibold text-primary">Apakah Anda akan menerima permintaan alih rawat ini?
          </h3>

          <div class="mt-4">
            <a href="javascript:;" @click="doSave('DONE')" class="btn btn-success btn-labeled btn-labeled-left mr-2">
              <b><i class="icon-checkmark"></i></b>
              Ya, Konfirmasi
            </a>

            <a href="javascript:;" @click="doSave('REJECT')" class="btn btn-danger btn-labeled btn-labeled-left">
              <b><i class="icon-close2"></i></b>
              Tidak
            </a>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>


<script>
import $ from 'jquery'
const _ = global._

import Gen from '@/libs/Gen.js'
import GlobalVue from '@/libs/Global.vue'
const moment = require('moment')

export default{
  extends: GlobalVue,
  computed: {
    now(){
      return moment().format('DD MMM YYYY')
    },
    isParent(){
      return this.$parent.$parent.$parent
    },
    jamPindah(){
      return moment().format('HH:mm')
    }
  },
  methods: {
    doSave(isSetuju){
      let data = this.isParent.rowEdit
      data.aranrp_status = isSetuju
      data.type = 'verif-data'

      this.isParent.loadingOverlay = true
      return Gen.apiRest(
          "/do/"+this.isParent.modulePage,
          {data: data}, 
          "POST"
      ).then(res=>{
        this.isParent.loadingOverlay = false
        let resp = res.data
        resp.statusType = 200
        this.$swal({
          title: resp.message,
          icon: resp.status,
          confirmButtonText: 'Ok',
          allowOutsideClick: false,
          allowEscapeKey: false
        }).then(result => {
          this.isParent.openModal = false
          this.isParent.apiGet()
        })
      }).catch(err => {
        this.isParent.loadingOverlay = false
        if (err) {
            err.statusType = err.status
            err.status = "error"
            err.title = err.response?.data?.title
            err.message = err.response?.data?.message
            err.messageError = err.message
        }
        this.isParent.doSetAlertForm(err)
      })
    }
  },
}

</script>