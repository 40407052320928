<template>
  <div class="content-wrapper">
    <div v-if="isList" class="content pt-0">
        <bo-page-title />
        <div class="card">
            <div class="bg-white card-header">
                <div class="row align-items-center">
                    <div class="col-md-4 col-lg-5">
                        <div class="form-row align-items-center">
                            <div class="col-lg-4">
                                <h5 class="card-title font-weight-semibold">Daftar Pasien</h5>
                            </div>
                            <div class="col-lg-8">
                              <div class="form-group mb-0">
                                <div class="input-group">
                                    <date-range-picker
                                        ref="picker"
                                        :locale-data="datePickerConfig.locale"
                                        :autoApply="datePickerConfig.autoApply"
                                        v-model="dateRange"
                                        :opens="'right'"
                                        :ranges="datePickerConfig.ranges"
                                        @update="updateValues"
                                    >
                                        <template v-slot:input="picker">
                                            {{ picker.startDate | date }} - {{ picker.endDate | date }}
                                        </template>
                                    </date-range-picker>
                                    <div class="input-group-append calendar-group">
                                        <span class="input-group-text" id="basic-addon2"><i class="icon-calendar"></i></span>
                                    </div>
                                </div>
                              </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            <div class="card-header">
                <div class="row">
                    <div class="col-md-8 col-lg-9">
                        <div class="row gx-1">
                            <div class="col-md-auto">
                                <div class="form-group mb-0">
                                    <button @click="doRefreshData" data-toggle="modal" data-target="#FilterField" data-popup="tooltip" class="btn btn-light" title="Muat Ulang" v-b-tooltip.hover.right ><i class="icon-spinner11"></i></button>
                                </div>
                            </div>     
                            <div class="col-md">
                                <div class="row g-1">
                                    
                                    <div class="col-md-6 col-lg-4">
                                        <b-form-group class="mb-0">
                                            <v-select placeholder=" -- Pilih DPJP -- " @input="doFill" v-model="filter.dpjp"  :options="mDokterDPJP" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                                        </b-form-group>
                                    </div>      
                                    
                                    <div class="col-md-6 col-lg-3">
                                        <b-form-group class="mb-0">
                                            <v-select placeholder=" -- Pilih Kelas -- " @input="doFill" v-model="filter.kelas"  :options="mKelas" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                                        </b-form-group>
                                    </div>  

                                    <div class="col-md-6 col-lg-3">
                                        <b-form-group class="mb-0">
                                            <v-select placeholder=" -- Pilih Ruangan -- " @input="doFill" v-model="filter.ruangan"  :options="mRuangan" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                                        </b-form-group>
                                    </div>                                                             
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 col-lg-3">
                        <div class="form-group form-group-feedback form-group-feedback-left mb-0 d-flex">
                            <input class="form-control"
                                v-model="filter.search"
                                @input="doSearch"
                                placeholder="Ketik Nama/No. RM Pasien"
                            />
                            <div class="form-control-feedback">
                                <i class="icon-search4 text-indigo"></i>
                            </div>
                                
                            <b-button
                                class="ml-1 d-inline-flex align-items-center"
                                variant="outline-success"
                                id="resetBtn"
                                @click="doResetData()"
                            >Reset</b-button>
                        </div>
                    </div>
                </div>
            </div>     

                  
            
            <div class="table-responsive">
                <table
                    class="table table-bordered table-striped table-hover table-sm patient-table text-uppercase table-sm">
                    <thead>
                        <tr>
                            <th>NO.</th>
                            <th>NO. KEDATANGAN</th>
                            <th>NAMA/NO KTP</th>                              
                            <th>NO RM</th>
                            <th>RUANGAN</th>
                            <th>DIAGNOSA</th>
                            <th>DOKTER DPJP</th>     
                            <th>TANGGAL TINDAKAN</th>
                            <th>STATUS</th>
                            <th>AKSI</th>
                        </tr>
                    </thead>
                    
                    <tbody v-if="(dataList||[]).length">
                        <template v-for="(v,k) in (dataList||[])">
                            <tr :key="k" :class="v.aranrp_status == 'WAITING' ? 'table-warning':''">
                              <td>{{(data.per_page*(data.current_page-1))+k+1}}</td>
                              <td>
                                <span class="font-weight-semibold">{{v.aranr_reg_code}}</span>
                              </td>
                              <td>
                                <div class="d-flex flex-column align-items-start">
                                  <a href="javascript:;" v-if="v.aranrp_status == 'PROSES' || v.aranrp_status == 'DONE'" @click="toMonitoring(v.aranr_id)" class="font-weight-semibold border-bottom">{{v.ap_fullname||"-"}}</a>
                                  <span v-else>{{v.ap_fullname||"-"}}</span>

                                  <small>{{v.ap_code}}</small>
                                  <small class="text-secondary">Terdaftar {{v.aranr_reg_date | moment("DD MMMM YYYY, HH:mm")}}</small>
                                </div>
                              </td>
                              <td>
                                <div class="font-weight-semibold">{{v.ap_code||"-"}}</div>
                              </td>
                              <td>{{getConfigDynamic(mRuangan,v.aranres_bangsal)||"-"}} </td>
                              <td>{{v.aranrp_diagnosa||"-"}}</td>
                              <td>{{getConfigDynamic(mDokterDPJP,v.aranr_dokter_id)||"-"}} </td>
                              <td>
                                <span v-if="v.aranrp_date">{{v.aranrp_date | moment("DD MMMM YYYY, HH:mm")}}</span>
                                <span v-else> - </span>
                              </td>
                              <td>
                                <template v-if="v.aranrp_is_draft == 'Y'">
                                  <span class="badge bg-warning mb-1">Draft </span>
                                </template>
                                <template v-else>
                                  <div v-b-tooltip.hover title="Status">
                                    <span v-if="v.aranrp_status == 'WAITING'" class="badge bg-primary mb-1">Menunggu </span>
                                    <span v-else-if="v.aranrp_status == 'PROSES'" class="badge bg-info mb-1">Dalam Tindakan</span>
                                    <span v-else class="badge bg-success mb-1">Selesai </span>
                                  </div>
                                </template>
                              </td>
                              <td>
                                <a href="javascript:;" v-if="moduleRole('ikhtisar_klinik')" @click="toModal(v,'lg','Ikhtisar Klinik','IkhtisarKlinik')" class="btn btn-icon rounded-round btn-sm alpha-warning border-warning text-warning-800" v-b-tooltip.hover title="Lihat Ikhtisar Klinik">
                                  <i class="fa-solid fa-clock"></i>
                                </a>
                                
                                <a href="javascript:;" v-if="(v.aranrp_status == 'PROSES' || v.aranrp_status == 'DONE') && moduleRole('tambah_cppt')" @click="toCPPT(v)" class="btn btn-icon rounded-round btn-sm alpha-success border-success text-success" v-b-tooltip.hover title="Tambah CPPT">
                                  <i class="fas fa-notes-medical"></i>
                                </a>
                                
                                <a href="javascript:;" v-if="(v.aranrp_status == 'DONE')" @click="unduhLaporan(v)" class="btn btn-icon rounded-round btn-sm alpha-primary border-primary text-primary" v-b-tooltip.hover title="Unduh Dokumen">
                                  <i class="icon-download"></i>
                                </a>
                              </td>
                            </tr>
                        </template>
                    </tbody>
                    <tbody v-if="data.data.length == 0">
                        <tr>
                            <td colspan="99">
                            <div class="text-center">
                                <h4 align="center"><span v-if="filter.status || filter.search || filter.startDate || filter.endDate">Hasil pencarian tidak ditemukan</span>
                                <span v-else>Belum Ada Data {{ pageTitle }} yang terdaftar</span></h4>
                            </div>
                            </td>
                        </tr>
                    </tbody>
                    <tbody v-if="!data.data">   
                        <tr>
                            <td colspan="99"><div class="skeletal-comp"></div></td>
                        </tr>
                        <tr>
                            <td colspan="99"><div class="skeletal-comp"></div></td>
                        </tr>
                        <tr>
                            <td colspan="99"><div class="skeletal-comp"></div></td>
                        </tr>
                    </tbody>
                </table>

                
                <div :class="(dataList||[]).length && !loadingTable && dataList ? 'table-scroll-actions' : 'table-scroll-actions d-none'">
                    <a href="javascript:;" data-scroll="left" class="btn btn-icon alpha-info border-info text-info-800 rounded-round">
                    <i class="icon-chevron-left"></i>
                    </a>
                    <a href="javascript:;" data-scroll="right" class="btn btn-icon alpha-info border-info text-info-800 rounded-round">
                    <i class="icon-chevron-right"></i>
                    </a>
                </div>
            </div>
            
            <b-card-footer class="pagination-custom" v-if="(dataList||[]).length&&data.total>data.per_page">     <b-pagination
                class="mb-0"
                v-model="pageNo"
                :per-page="data.per_page"
                :total-rows="data.total"
            />
            </b-card-footer>

        </div>

        <b-modal
          v-model="openModal"
          :size="modalConfig.size"
          no-close-on-backdrop
          hide-footer
          :title="modalConfig.title" 
        > 
          <IkhtisarKlinik v-if="isModal == 'IkhtisarKlinik'" />     
        </b-modal>
    </div>
  </div>
</template>


<script>
  import GlobalVue from '@/libs/Global.vue'
  import DateRangePicker from 'vue2-daterange-picker'
  import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
  const moment = require('moment')
  import IkhtisarKlinik from './IkhtisarKlinik.vue'

  // -->

  const _ = global._
  import $ from 'jquery'
  import Gen from '@/libs/Gen.js'
  export default {
    extends: GlobalVue,
    components:{
      DateRangePicker,
      IkhtisarKlinik
    },
    data(){
      return {
        idKey:'aranres_id',
        datePickerConfig: {
          startDate: new Date(moment().subtract(6, 'days')),
          endDate: new Date(),
          autoApply: true,
          ranges: {
              'Hari Ini': [new Date(), new Date()],
              '7 Hari Terakhir': [new Date(moment().subtract(6, 'days')), new Date()],
              '30 Hari Terakhir': [new Date(moment().subtract(29, 'days')), new Date()],
          },
          applyClass: 'btn-sm btn-primary',
          cancelClass: 'btn-sm btn-light',
          locale: {
              applyLabel: 'Terapkan',
              cancelLabel: 'Batal',
              direction: 'ltr',
              format: 'mm/dd/yyyy',
              separator: ' - ',
          }
        },
        dateRange: {
          startDate: new Date(moment().subtract(6, 'days')),
          endDate: new Date(),
        },
        mDokterDPJP: [],
        mKelas: [],
        mRuangan: [],

        modalConfig: {
          title: '',
          size: 'sm'
        },
        isModal: '',
        rowEdit: {},
        openModal: false
      }
    },
    methods: {
      unduhLaporan(rowEdit){
        let data = {exptype: 'xlsx', type: "export", id: rowEdit.aranrp_id}
        let self = this
        $.ajax({
            type: "POST",
            url: process.env.VUE_APP_API_URL + `/report/${'ranapAlihRawat'}?token=IXs1029102asoaksoas102901290`,
            data: data,
            cache: false,
            xhrFields:{
                responseType: 'blob'
            },
            success: data => 
            {
                self.loadingOverlay = false
                var link = document.createElement('a')
                link.href = window.URL.createObjectURL(data)
                link.download = `Rawat-Inap-Alih-Rawat-${moment().format("YYYY-MM-DD")}.pdf`
                link.click()
            },
            fail: data => {
                self.loadingOverlay = false
                alert('Not downloaded')
            }
        })
      },
      toCPPT(row){
        this.$router.push({ name: 'RanapCPPT', params: {pageSlug: row.aranrp_aranr_id}, query: {konsulId: row.aranrp_id}}).catch(()=>{})
      },
      toMonitoring(regID){
        this.$router.push({ name: 'RanapMonitoring', params: {pageSlug: regID}}).catch(()=>{})
      },  
      setSlide(){
        const responsiveTable = document.querySelector('.table-responsive')
        if(responsiveTable){
          const responsiveTableScroll = responsiveTable.querySelector('.table-scroll-actions')
          const responsiveTableScrollActions = responsiveTable.querySelectorAll('.btn')
          if(responsiveTableScroll){
            // responsiveTableScroll.style.top = (document.documentElement.scrollTop + (window.innerHeight / 1.5) - 32)+'px'
            Array.from(responsiveTableScrollActions).forEach(action => {
              action.addEventListener('click', e => {
                const scrollAmount = action.dataset.scroll == 'right' ? responsiveTable.scrollLeft + 100 : responsiveTable.scrollLeft - 100
                  
                responsiveTable.scroll({
                    left: scrollAmount,
                    behavior: 'smooth'
                })
              })
            })
          }
        }
      },
      doResetData(){
        this.doReset()
        this.dateRange =  {
          startDate: new Date(moment().subtract(6, 'days')),
          endDate: new Date(),
        }
        this.doConvertDate()
      },
      doConvertDate(){
        this.filter.startDate = moment(this.dateRange.startDate).format('YYYY-MM-DD')
        this.filter.endDate = moment(this.dateRange.endDate).format('YYYY-MM-DD')
      },
      doPageOne(){
        this.filter.page = 1
      },
      doSearch: _.debounce(function(){
        this.doPageOne()
        this.doFilter()
      },500),
      doFill(){
        this.doPageOne()
        this.doFilter()
      },
      updateValues(e){
        this.doConvertDate()
        this.doFill()
      },
      doRefreshData(){
        this.apiGet()
        window.scrollTo(0,0)
      },
      getConfigDynamic(master,value){
        let text = ''
        if(value){
            let index = (master||[]).findIndex(x => x.value == value)
            if(index !== -1){
                text = master[index]['text']
            }
        }
        return text
      },
      toModal(row,size,title,isModal){
        this.rowEdit = row
        this.modalConfig = {
          title: title,
          size: size
        }
        this.isModal = isModal
        this.openModal = true
      },
    },
    mounted(){
      this.filter.dpjp = 'ALL'
      this.filter.kelas = 'ALL'
      this.filter.ruangan = 'ALL'
    
      this.doConvertDate()      
      this.apiGet()
          
      setTimeout(()=>{
        this.setSlide()
      },1000)
    },
    filters: {
      date(val) {
        return val ? moment(val).format("D MMM YYYY") : ""
      }
    },
    watch:{
      $route(){
        this.apiGet()
        setTimeout(()=>{
          if(this.isList){
            this.setSlide()
          }
        },1000)
      }
    }
  }
</script>